<!-- //科技管理 -->
<template>
  <div>
    <!-- <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">标题</span>
        <el-input v-model="title" style="width: 60%;" placeholder="请输入内容"></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">申请人</span>

        <el-input v-model="contractName" style="width: 60%;" placeholder="请输入申请人"></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">

      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
                                          </el-row> -->

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未处理" name="1"></el-tab-pane>
      <el-tab-pane label="已处理" name="2"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <!-- <el-table-column label="申请类型" width="250">
        <template slot-scope="scope">
          {{ getApplyType(scope.row.basicType) }}
        </template>
                        </el-table-column> -->
      <el-table-column prop="applyName" label="申请人"> </el-table-column>
      <el-table-column prop="applyPhone" label="申请人电话"> </el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="applyTime" label="申请时间"> </el-table-column>
      <el-table-column v-if="activeName == 1" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-popconfirm title="确认接受请求？" @confirm="publish(scope.row)">
            <el-button slot="reference" type="text" size="small">接受请求</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createServe type="technology" :dialogVisible.sync="dialogVisible" @updateList="getList"> </createServe>
  </div>
</template>

<script>
import createServe from '../../../components/createServe.vue';
import { applyListUrl, applyApprove1 } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    createServe
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getApplyType(type) {
      let msg = '';
      switch (type) {
        case 11:
          msg = '土地测评';
          break;
        case 12:
          msg = '实验室测士';
          break;
        case 13:
          msg = '科研咨询';
          break;
        case 14:
          msg = '农事咨询';
          break;
        default:
          break;
      }
      return msg;
    },
    toDetail(item) {
      // this.$router.push({ name: 'detailService', query: { id: item.id } })
    },
    search() {
      this.searchParams = {
        title: this.title,
        contractName: this.contractName
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    publish(item) {
      applyApprove1({ id: item.id }, '/api/operate/govserv/machine/apply/approve')
        .then((res) => {
          this.$message({
            message: '处理成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      applyListUrl(
        {
          state: this.activeName,
          basicType: 22,
          pageNum: this.pageInfo.currentPage,
          pageSize: this.pageInfo.pageSize,
          ...this.searchParams
        },
        '/api/operate/govserv/machine/apply/page'
      )
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
