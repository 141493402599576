<!-- //科技管理 -->
<template>
  <div>
    <!-- <div class="total-info">
      <img src="@/images/dataSource_icon.png" alt="" />
      <div class="detail-wrap" v-if="sqlDataSource">
        <div class="title">数据库信息</div>
        <div class="total-item">
          <span class="label">数据库用户名：</span>
          <span class="value">{{ sqlDataSource.UserName }}</span>
          <span class="label">数据库类型</span>
          <span class="value">{{ sqlDataSource.DbType }}</span>
        </div>
        <div class="total-item">
          <span class="label">连接地址：</span>
          <span class="value">{{ sqlDataSource.URL }}</span>
        </div>
      </div>
    </div> -->
    <el-alert title="系统数据会在每天凌晨自动进行备份，路径：/data/backup/mysqldump" type="warning" />
    <div class="sql-title">任务监控</div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 100%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="SQL" label="任务" width="250" show-overflow-tooltip>
        <template slot-scope="scope">{{ getSqlType(scope.row.SQL) }}</template>
      </el-table-column>
      <el-table-column prop="FetchRowCountHistogram" label="执行数">
        <template slot-scope="scope">{{ scope.row.FetchRowCountHistogram.length || 0 }}</template>
      </el-table-column>
      <el-table-column prop="TotalTime" label="执行时间"> </el-table-column>
      <el-table-column prop="MaxTimespan" label="最慢"> </el-table-column>
      <!-- <el-table-column prop="Txn" label="事务执行"> </el-table-column> -->
      <el-table-column prop="ErrorCount" label="错误数"> </el-table-column>
      <!-- <el-table-column prop="applyTime" label="更新行数"> </el-table-column> -->
      <el-table-column prop="RunningCount" label="读取行数"> </el-table-column>
      <el-table-column prop="ConcurrentMax" label="最大并发"></el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0 || tableData.length"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="hasNext ? tableData.length * pageInfo.currentPage + pageInfo.pageSize : pageInfo.pageSize * pageInfo.currentPage"
      >
      </el-pagination>
    </div>
    <!-- <createServe type="technology" :dialogVisible.sync="dialogVisible" @updateList="getList"> </createServe> -->
  </div>
</template>

<script>
import createServe from '../../../components/createServe.vue';
import { sqlList, dataSource } from '@/api/api.js';
export default {
  components: {
    createServe
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '2',

      tableData: [],
      sqlDataSource: null,
      hasNext: false
    };
  },
  created() {},
  mounted() {
    this.getDataSource();
    this.getList();
  },
  methods: {
    getSqlType(val) {
      val = val.trim();
      if (val.startsWith('delete')) {
        return '删除任务';
      }
      if (val.startsWith('update')) {
        return '更新任务';
      }
      if (val.startsWith('insert')) {
        return '创建任务';
      }
      if (val.startsWith('sel')) {
        return '查询任务';
      }
    },
    getDataSource() {
      dataSource()
        .then((res) => {
          let content = res.Content || [];
          if (content.length) {
            this.sqlDataSource = content[0];
          }
        })
        .catch((error) => {});
    },
    getList() {
      let params = {
        page: this.pageInfo.currentPage,
        perPageCount: this.pageInfo.pageSize
      };
      sqlList(params)
        .then((res) => {
          this.tableData = res.Content;
          if (res.Content && res.Content.length) {
            this.hasNext = true;
          } else {
            this.hasNext = false;
          }
        })
        .catch((error) => {});
    },

    handleSizeChange(e) {
      if (this.hasNext) {
        this.pageInfo.currentPage = e;
        this.getList();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.total-info {
  display: flex;
  border-bottom: 1px solid #ebedf4;
  padding-bottom: 20px;
  img {
    width: 100px;
    height: 100px;
  }
  .detail-wrap {
    padding: 10px 0;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      height: 16px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2c3542;
      line-height: 16px;
    }
    .total-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      .label {
        width: 120px;
      }
      .value {
        color: #2c3542;
        margin-right: 40px;
      }
    }
  }
}
.sql-title {
  height: 46px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c3542;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 3px;
    height: 14px;
    background: #1890ff;
    border-radius: 2px;
    margin-right: 4px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
