<!-- //农机管理 -->
<template>
  <div>
    <template v-if="serverList && serverList.length">
      <div class="list-warp" v-for="(item, index) in serverList" :key="item.computerIp">
        <div class="title">
          <span class="server-name">服务器{{ index + 1 }}</span>
          <span>磁盘总量：{{ item.serverInfo.sysFiles[0].total }}</span>
          <span>磁盘剩余大小：{{ item.serverInfo.sysFiles[0].free }}</span>
          <span class="clean" @click="cleanServer(item)"> <img class="clean-icon" src="@/images/clean-icon.png" />清理垃圾</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item">
              <div class="name"><img src="@/images/sys_icon.png" />服务器信息</div>
              <div class="detail-info">
                <span class="label">服务器名称：</span>
                <span class="value">{{ item.serverInfo.sys.computerName }}</span>
              </div>
              <div class="detail-info">
                <span class="label">服务器ip：</span>
                <span class="value">{{ item.serverInfo.sys.computerIp }}</span>
              </div>
              <div class="detail-info">
                <span class="label">操作系统：</span>
                <span class="value">{{ item.serverInfo.sys.osName }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="name"><img src="@/images/cpu_icon.png" />CPU信息</div>
              <div class="detail-info">
                <span class="label width120">核心数：</span>
                <span class="value">{{ item.serverInfo.cpu.cpuNum }}</span>
              </div>
              <div class="detail-info">
                <span class="label width120">CPU系统使用率：</span>
                <span class="value">{{ item.serverInfo.cpu.sys }}</span>
              </div>
              <div class="detail-info">
                <span class="label width120">CPU用户使用率：</span>
                <span class="value">{{ item.serverInfo.cpu.used }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="name"><img src="@/images/ram_icon.png" />内存信息</div>
              <div class="detail-info">
                <span class="label">总大小：</span>
                <span class="value">{{ item.serverInfo.mem.total }}</span>
              </div>
              <div class="detail-info">
                <span class="label">剩余大小：</span>
                <span class="value">{{ item.serverInfo.mem.free }}</span>
              </div>
              <div class="detail-info">
                <span class="label">已使用量：</span>
                <span class="value">{{ item.serverInfo.mem.used }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="name"><img src="@/images/jvm_icon.png" />JVM信息</div>
              <div class="detail-info">
                <span class="label width160">JVM空闲内存：</span>
                <span class="value">{{ item.serverInfo.jvm.free }}</span>
              </div>
              <div class="detail-info">
                <span class="label width160">JVM最大可用内存总数：</span>
                <span class="value">{{ item.serverInfo.jvm.max }}</span>
              </div>
              <div class="detail-info">
                <span class="label width160">已用内存：</span>
                <span class="value">{{ item.serverInfo.jvm.used }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </template>
    <el-dialog class="cleanDio" :visible="cleanDialog" width="60%" @close="closeMe">
      <div class="dg-content">
        <span class="close-icon icon iconfont icon-guanbi" @click="closeMe"></span>
        <div class="clean-bg">
          <div class="clean-bg1"> </div>
          <div class="img-div">
            <div class="img-div-img rotate-center"></div>
            <div class="img-div-content">
              <div class="num">{{ startNum.toFixed(2) }} <div class="unit">GB</div></div>
              <div class="tip">总共清除垃圾</div>
            </div>
          </div>
        </div>
        <div class="close-btn" @click="closeMe"> 关闭 </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { serverInfo, getRequest } from '@/api/api.js';
export default {
  components: {},
  data() {
    return {
      serverList: null,
      cleanDialog: false,
      endNum: 0,
      startNum: 0,
      interval: null
    };
  },
  created() {
    this.getServerInfo();
  },
  mounted() {},
  methods: {
    closeMe() {
      this.cleanDialog = false;
    },
    cleanServer(item) {
      getRequest('/api/common/monitor/server/optimize', {
        ip: item.serverInfo.sys.computerIp
      })
        .then((res) => {
          this.startNum = 0;
          this.endNum = res;
          this.cleanDialog = true;
          this.startLoading();
        })
        .catch((e) => {});
    },
    startLoading() {
      let per = this.endNum / (10 * 3);
      this.interval = setInterval(() => {
        if (this.startNum >= this.endNum) {
          this.startNum = this.endNum;
          clearInterval(this.interval);
        } else {
          this.startNum += per;
        }
      }, 100);
      clearInterval;
    },
    getServerInfo() {
      serverInfo()
        .then((res) => {
          this.serverList = res;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.list-warp {
  height: 205px;
  background: #f8f8f9;
  padding: 0 20px 15px;
  margin-bottom: 20px;
  .title {
    height: 46px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c3542;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .server-name {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        width: 3px;
        height: 14px;
        background: #1890ff;
        border-radius: 2px;
        margin-right: 4px;
      }
    }
  }
  .item {
    background: #ffffff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    padding: 20px;
    .name {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2c3542;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
    .detail-info {
      display: flex;
      align-items: center;
      margin-left: 28px;
      margin-top: 10px;
      .label {
        width: 90px;
        color: #909399;
      }
      .width120 {
        width: 120px;
      }
      .width160 {
        width: 160px;
      }
      .value {
        flex: 1;
        color: #2c3542;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.clean {
  font-size: 14px;
  color: #7e88a8;
  cursor: pointer;
  vertical-align: middle;
}
.clean-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  padding: 0 5px;
  vertical-align: middle;
}

:deep(.cleanDio) {
  background-color: transparent;
  box-shadow: none;
  .el-dialog {
    background-color: transparent;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.dg-content {
  width: 983px;
  height: 511px;
  background-color: #1f2e54;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .close-icon {
    font-size: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    z-index: 1;
  }
}
.clean-bg {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  background: url('@/images/clean_bg.png') center center no-repeat;
  background-size: 100% 100%;
  .img-div {
    width: 220px;
    height: 220px;
    position: relative;
    .img-div-img {
      width: 220px;
      height: 220px;
      background: url('@/images/fengshan.png') center center no-repeat;
      background-size: 100% 100%;
    }
    .img-div-content {
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      .tip {
        color: #ffffff;
        font-size: 18px;
      }
      .num {
        color: #ffffff;
        font-weight: bold;
        font-size: 48px;
        position: relative;
        .unit {
          position: absolute;
          right: -30px;
          top: 0;
          color: #ffffff;
          font-size: 18px;
        }
      }
    }
  }
}
.close-btn {
  width: 240px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background: #02b98f;
  border-radius: 8px;
  margin-top: 30px;
  cursor: pointer;
}
.rotate-center {
  -webkit-animation: rotate-center;
  animation: rotate-center;
  animation-duration: 5s;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
</style>
