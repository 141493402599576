<!-- //无人机管理 -->
<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">标题</span>
        <el-input v-model="title" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">联系人</span>

        <el-input v-model="contractName" style="width: 60%" placeholder="请输入联系人" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row"> </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未发布" name="2"></el-tab-pane>
      <el-tab-pane label="已发布" name="1"></el-tab-pane>
    </el-tabs>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (isDisable = false), (dialogVisible = true)">新建服务</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column fixed prop="title" label="标题"> </el-table-column>
      <el-table-column prop="contractName" label="联系人"> </el-table-column>
      <el-table-column prop="contractPhone" label="联系电话"> </el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip> </el-table-column>

      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-popconfirm v-if="activeName == 2" title="确认发布吗？发布后将展示在手机端，用户可进行查看" @confirm="publish(scope.row)">
            <el-button slot="reference" type="text" size="small">发布</el-button>
          </el-popconfirm>
          <el-button v-if="activeName == 2" slot="reference" type="text" size="small" @click="toEdit(scope.row)">修改</el-button>
          <!-- <el-popconfirm v-if="activeName == 2" title="确认删除?" @confirm="confirmApply(scope.row)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
                  </el-popconfirm> -->
          <el-popconfirm v-if="activeName == 1" title="确认取消发布?" @confirm="unpublished(scope.row)">
            <el-button slot="reference" type="text" size="small">取消发布</el-button>
          </el-popconfirm>
          <el-button v-if="activeName == 1" slot="reference" type="text" size="small" @click="toDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createServe
      type="machine"
      :isDisable="isDisable"
      :dialogVisible.sync="dialogVisible"
      :isEdit="isEdit"
      :currentId="currentId"
      @updateList="getList"
    >
    </createServe>
  </div>
</template>

<script>
import createServe from '../../../components/createServe.vue';
import { machineList, unpublishedService1, publishedService1 } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    createServe
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      isDisable: false,
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '2',

      tableData: []
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    toDetail(item) {
      // this.$router.push({ name: 'detailService', query: { id: item.id } })
    },
    search() {
      this.searchParams = {
        title: this.title,
        contractName: this.contractName
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.isDisable = false;
      this.dialogVisible = true;
    },
    toDetail(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.isDisable = true;
      this.dialogVisible = true;
    },
    publish(item) {
      publishedService1({ id: item.id }, '/api/operate/govserv/machine/publish')
        .then((res) => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    unpublished(item) {
      unpublishedService1({ id: item.id }, '/api/operate/govserv/machine/unpublished')
        .then((res) => {
          this.$message({
            message: '取消发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      machineList({ state: this.activeName, pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ...this.searchParams })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
